import { getData } from ".";

export const getBusinessList = async ({ token }) => {
  const res = await getData({
    endpoint: "BusinessManagement/getBusinessList",
    params: {},
    token,
  });
  return res;
};

export const getRolesList = async ({ token }) => {
  const res = await getData({
    endpoint: "AdminConfiguration/getRolesList",
    token,
  });
  return res;
};

export const checkEmailExists = async ({ token, email }) => {
  const res = await getData({
    endpoint: "BusinessUser/checkEmail",
    params: { email: email },
    token,
  });
  return res;
};
