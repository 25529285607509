import React from 'react'
import Invalid from '../../../assets/images/invalid.png'
import styles from './error.module.scss'

const Error = ({error}) => {
  return (
    <div className={`${styles.error_div} d-flex`}>
        <img src={Invalid} height="16px" className="me-2" alt='error'/>
        {error}
    </div>
  )
}

export default Error