import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import PageCard from "../../../../atoms/PageCard";
import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import InputLayout from "../../../../atoms/InputLayout";
import { getData } from "../../../../../services";
import { getValidityText } from "../../../../../helpers/getValidityText";
import moment from "moment";

const View = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [paymentData, setPaymentData] = useState();

  const getPaymentDetail = async () => {
    const res = await getData({
      endpoint: "PaymentManagement/getPaymentDetailsById",
      token: cookies.t,
      params: { id },
    });
    setPaymentData(res);
  };

  useEffect(() => {
    getPaymentDetail();
  }, []);

  return (
    <>
      <PageHeader title="View payment">
        <BackBtn />
      </PageHeader>

      <PageCard classes="view-container">
        <div className="row g-4">
          <InputLayout>
            <label htmlFor="name">Company Name</label>
            <div className="value">{paymentData?.business_name}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="name">Contact Number</label>
            <div className="value">
              {paymentData?.country_code} {paymentData?.contact_number}
            </div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="name">Email Id</label>
            <div className="value">{paymentData?.email}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Transaction Id</label>
            <div className="value">{paymentData?.transaction_id}</div>
          </InputLayout>

          <InputLayout>
            <label>Payment Mode</label>
            <div className="value">{paymentData?.payment_mode}</div>
          </InputLayout>

          <InputLayout>
            <label>Plan Name</label>
            <div className="value">{paymentData?.plan_name}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Plan Validity</label>
            <div className="value">{getValidityText(
          paymentData?.period_month,
          paymentData?.period_day
        )}</div>
          </InputLayout>

          <InputLayout>
            <label>Amount</label>
            <div className="value">{paymentData?.amount}</div>
          </InputLayout>

          <InputLayout>
            <label>Purchase Date</label>
            <div className="value">
              {moment
                .utc(paymentData?.purchase_date)
                .format("DD/MM/YYYY hh:mm a")}
            </div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label>Subscription Start Date</label>
            <div className="value">
              {moment
                .utc(paymentData?.subscription_start_date)
                .format("DD/MM/YYYY hh:mm a")}
            </div>
          </InputLayout>

          <InputLayout>
            <label>Subscription End Date</label>
            <div className="value">
              {moment
                .utc(paymentData?.subscription_end_date)
                .format("DD/MM/YYYY hh:mm a")}
            </div>
          </InputLayout>

          <InputLayout>
            <label>Address</label>
            <div className="value">{paymentData?.address}</div>
          </InputLayout>
        </div>
      </PageCard>
    </>
  );
};

export default View;
