import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../quillEditor.scss";
import { getData, patchData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";

const TermsAndConditions = () => {
  const [editorValue, setEditorValue] = useState("");
  const [cookies] = useCookies(["t"]);

  useEffect(() => {
    getTermsandconditions();
  }, []);

  const getTermsandconditions = async () => {
    try {
      const response = await getData({
        endpoint: "AdminConfiguration/getPage",
        token: cookies.t,
        params: {
          id: 2,
        },
      });
      if (response) setEditorValue(response.text);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await patchData({
        endpoint: "AdminConfiguration/editPage",
        token: cookies.t,
        params: {
          id: 2,
        },
        data: editorValue,
      });
      if (response.status) {
        toast.success("Privacy Policy Updated Succesfully", {
          style: confimationStyles,
          duration: 1000,
        });
        getTermsandconditions();
      } else {
        toast.error(response.message, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 style={{ fontSize: "24px", fontWeight: 600 }}>
          Terms and Conitions
        </h2>

        <button className={`btn btn-primary px-4 mt-0 `} onClick={handleSave}>
          Save
        </button>
      </div>
      <div style={{ height: "400px" }}>
        <ReactQuill
          style={{ borderRadius: "12px", height: "85%" }}
          theme="snow"
          value={editorValue}
          onChange={setEditorValue}
          placeholder="Enter Terms and Conitions"
          contentType="html"
        />
      </div>
    </>
  );
};

export default TermsAndConditions;
