import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../molecules/navbar";
import Footer from "../../molecules/footer";

import styles from "./dashboard.module.scss";
import { dashboardRoute } from "../../../routes/routes";

const DashboardLayout = () => {
  return (
    <div className={styles.dashboard}>
      <Navbar />
      <div className={styles.dashboard_body}>
        <Outlet />
        {dashboardRoute}
      </div>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
