import addMonths from "date-fns/addMonths";
import endOfDay from "date-fns/endOfDay"; // Import endOfDay to set the end of the day for the end date
import startOfDay from "date-fns/startOfDay";
import subMonths from "date-fns/subMonths"; // Import to subtract a month from the current date
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { subDays, startOfMonth, endOfMonth } from "rsuite/esm/internals/utils/date";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import style from "./index.module.scss";
import "./dateRangePicker.scss";
import "rsuite/DateRangePicker/styles/index.css";

export const adjustToUTC = (date) => {
  const utcDate = new Date(date?.getTime() - date?.getTimezoneOffset() * 60000);
  return utcDate.toISOString(); // Keeps the date part only
};

const DateRangePickerShortCuts = ({
  setDate,
  className,
  type,
  placement = "bottomEnd",
  disableFuture, disablePast,
}) => {
  const todays = new Date();
  const defaultToday = new Date();
  
  // Calculate last 1 month, from today to exactly 1 month back
  const oneMonthAgo = subMonths(todays, 1);

  const predefinedRanges = [
    {
      label: "Last 7 days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [startOfDay(new Date(new Date().getFullYear(), 0, 1)), endOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        startOfDay(new Date(new Date().getFullYear() - 1, 0, 1)),
        endOfDay(new Date(new Date().getFullYear(), 0, 0)),
      ],
      placement: "left",
    },
  ];

  const shouldDisableDate = (date) => {
    if (disableFuture && isAfter(date, new Date())) {
      return true;
    }
    if (disablePast && isBefore(date, new Date())) {
      return true;
    }
    return false;
  };

  // Default value is last 1 month: from oneMonthAgo (start of the day) to today (end of the day)
  const defaultVal = [startOfDay(oneMonthAgo), endOfDay(todays)];

  return (
    <div
      className={`${type === "header-filter" ? "header-daterange" : ""} ${
        style.dateRangeWrapper
      }`}
    >
      <div className={style.dateRangeShortCut}>
        <DateRangePicker
          className={`${style.dateRange} ${className}`}
          ranges={predefinedRanges}
          placeholder="Date Range Select"
          style={{ width: 245 }}
          character={" - "}
          editable
          format="dd/MM/yyyy"
          defaultCalendarValue={defaultVal}
          defaultValue={defaultVal}
          onShortcutClick={(shortcut) => {
            setDate(() => ({
              start_date: adjustToUTC(shortcut?.value?.[0]),
              end_date: adjustToUTC(shortcut?.value?.[1]),
            }));
          }}
          onChange={(data) => {
            if (data?.length) {
              setDate(() => ({
                start_date: adjustToUTC(startOfDay(data?.[0])),
                end_date: adjustToUTC(endOfDay(data?.[1])), // Set end date to end of the day
              }));
            } else {
              setDate(() => ({
                start_date: adjustToUTC(startOfDay(oneMonthAgo)),
                end_date: adjustToUTC(endOfDay(todays)),
              }));
            }
          }}
    
          shouldDisableDate={shouldDisableDate}
          appearance={"default"}
          placement={placement}
        />
      </div>
    </div>
  );
};

export default DateRangePickerShortCuts;
