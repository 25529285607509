import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { Toaster } from "react-hot-toast";
import "./assets/globalStyles/styles.scss";

const App = () => {
  const content = useRoutes(routes);
  return (
    <>
      {content} <Toaster />
    </>
  );
};

export default App;
