export const customDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    minheight: "46px",
    outline: state.isFocused ? "none" : "none",
    backgroundColor: state.isFocused ? "#ffffff" : "#F4F4F4", // Change to your focused background color
    border: state.isFocused
      ? "1px solid #6B66DA !important"
      : "1px solid #F4F4F4 !important", // Change to your focused border color and width
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: "12px",
    fontSize: "14px",
    fontWeight: 500,
    // ":hover": {
    //   border: "1px solid #3D9BE9", // Change to your hover border color and width
    // },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minheight: "46px",
    padding: 0,
  }),
  inputContainer: (provided) => ({
    ...provided,
    minHeight: "46px",
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#F8F8F8"
      : state.isSelected
      ? "#F4F4F4"
      : provided.backgroundColor,
    color: provided,
    ":hover": {
      backgroundColor: state.isFocused
        ? "#F8F8F8"
        : state.isSelected
        ? "#F4F4F4"
        : "#F4F4F4",
    },
  }),
  menu: (provided) => ({
    ...provided,
    // maxHeight: "200px", // Adjust this value based on your requirement
    overflowY: "auto",
    zIndex: 10,
    minWidth: "200px",
    border: "none",
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#1A1D1F !important",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set your desired color here
    borderRadius: "6px",
    border: "1px solid #efefef",
    padding: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px",
    fontWeight: 500,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set the background color for the remove button
    cursor: "pointer", // Add a pointer cursor to indicate it's clickable
    color: "#6F767E",
    borderRadius: "6px",
    ":hover": {
      backgroundColor: "#fff", // Set the background color on hover
    },
  }),
};

export const errorStyles = {
  control: (provided, state) => ({
    ...provided,
    minheight: "46px",
    outline: state.isFocused ? "none" : "none",
    backgroundColor: state.isFocused ? "#ffffff" : "#F4F4F4", // Change to your focused background color
    border: "1px solid #be0f00 !important", // Change to your focused border color and width
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: "12px",
    fontSize: "14px",
    fontWeight: 500,
  }),
};

export const filterStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused
      ? "1px solid #6B66DA !important"
      : "1px solid #F4F4F4 !important", // Change to your focused border color and width
    backgroundColor: state.isFocused ? "#ffffff" : "#F4F4F4",
    borderRadius: "24px",
    boxShadow: "none",
    paddingLeft: "15px",
    fontSize: "14px",
    fontWeight: 500,
  }),

  placeholder : (provided, state) => ({
    ...provided,
    color: '#6F767E !important',
    fontWeight  :'400'
  }),

  indicatorContainer: (provided) => ({
    ...provided,
    color: "#1A1D1F !important",
    marginRight : '10px !important'
  }),

};

export const dashboardStyles = {
  control: (provided, state) => ({
    ...provided,
    minheight: "45px",
    height:'46px',
    minWidth : '200px',
    backgroundColor: '#ffffff', // Change to your focused background color
    border: state.isFocused
      ? "1px solid #6B66DA !important"
      : "1px solid #e5e5ea !important", // Change to your focused border color and width
    borderRadius: "6px",
    boxShadow: "none",
    paddingLeft: "12px",
    fontSize: "14px",
    fontWeight: 500,
    outline : state.isFocused ? '3px solid #F4F5FF !important;' : 'none'
    // ":hover": {
    //   border: "1px solid #3D9BE9", // Change to your hover border color and width
    // },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minheight: "46px",
    height : '46px',
    padding: 0,
  }),
  inputContainer: (provided) => ({
    ...provided,
    minHeight: "46px",
    height : '46px',
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: "12px",
    height : '46px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#F8F8F8"
      : state.isSelected
      ? "#F4F4F4"
      : provided.backgroundColor,
    color: provided,
    ":hover": {
      backgroundColor: state.isFocused
        ? "#F8F8F8"
        : state.isSelected
        ? "#F4F4F4"
        : "#F4F4F4",
    },
  }),
  menu: (provided) => ({
    ...provided,
    // maxHeight: "200px", // Adjust this value based on your requirement
    overflowY: "auto",
    zIndex: 10,
    minWidth: "200px",
    border: "none",
    borderRadius: "6px",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#1A1D1F !important",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set your desired color here
    borderRadius: "6px",
    border: "1px solid #efefef",
    padding: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px",
    fontWeight: 500,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set the background color for the remove button
    cursor: "pointer", // Add a pointer cursor to indicate it's clickable
    color: "#6F767E",
    borderRadius: "6px",
    ":hover": {
      backgroundColor: "#fff", // Set the background color on hover
    },
  }),
};