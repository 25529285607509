import React, { CSSProperties } from "react";
import { getRandomColor } from "../../../helpers/generateRandomColor";
import "./IconCircle.scss";

const IconCircle = ({
  icon,
  color,
  size = 50,

  borderRadius = "50%",
  iconColor = "#1A1D1F",
  iconSize,
  border,
  isClickable,
  handleClick,
  svgIcon
}) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
    borderRadius: borderRadius,
    border: border,
    background: color || getRandomColor(),
    flexShrink: 0,
    cursor: isClickable ? "pointer" : "",
  };

  const iconStyle = {
    fontSize: iconSize || "1.2em", // Adjust the font size as needed
    color: `${iconColor} !important`, // You can customize the color of the icon separately
    height: "1em",
    width: "1em",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={containerStyle}
      className="address-icon-center"
      onClick={handleClick}
    >
      <span style={iconStyle}>{icon || svgIcon}</span>
    </div>
  );
};

export default IconCircle;
