import PropTypes from "prop-types";
import Add from "../../icons/Add";

const CustomButton = ({
  handleClick,
  icon,
  text,
  classes,
  type = "btn-outline-dark",
  buttonType = "button",
  style,
  disabled,
  forMobile,
  iconRequired = true,
  children
}) => {
  return (
    <button
      onClick={handleClick}
      className={`btn ${type} px-4 mt-0 ${classes}`}
      type={buttonType}
      style={style}
      disabled={disabled}
    >
      {iconRequired && (
        <i className={forMobile ? `d-flex` : `me-2 d-flex`}>
          {icon || <Add />}
        </i>
      )}

      {text || children}
    </button>
  );
};

CustomButton.propTypes = {
  handleClick: PropTypes.func,
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  forMobile: PropTypes.bool,
};

export default CustomButton;
