import React, { useState, useCallback, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Pagination from "../../../molecules/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { getData } from "../../../../services";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { useDelete } from "../../../../hooks/useDelete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import moment from "moment/moment";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";

const Plans = () => {
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);

  const [cookies] = useCookies(["t"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "SuperAdminReport/getReportSubscriptionPlan",
      token: cookies.t,
      fileName: "Plans Report",
    });

    if (res) {
      toast.success("Plans Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const getPlansData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "PlanManagement/getAllPlans",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getPlansData();
  }, [getPlansData]);

  const columns = [
    {
      field: "name",
      headerName: "Plan Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_date",
      headerName: "Created On",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.created_date).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      field: "plan_validity",
      headerName: "Plan Validity",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.row.period_month > 0 && (
              <>
                {` ${params.row.period_month} ${
                  params.row.period_month > 1 ? "Months" : "Month"
                }`}
              </>
            )}
            {params.row.period_day > 0 && (
              <>
                {` ${params.row.period_day} ${
                  params.row.period_day > 1 ? "Days" : "Day"
                }`}
              </>
            )}
          </span>
        );
      },
    },
    {
      field: "visitor_allowed",
      headerName: "Vistors Count",
      flex: 1,
      sortable: true,
    },
    {
      field: "price",
      headerName: "Base Price",
      flex: 1,
      sortable: true,
    },
    {
      field: "gst",
      headerName: "GST (%) ",
      flex: 0.5,
      sortable: true,
    },
    {
      field: "total_amount",
      headerName: "Total Price incl. GST",
      flex: 1,
      sortable: true,
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.is_active ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => navigate(`view/${params.row.id}`)}>
              <View />
            </div>
            {accessData?.["Subscription Plans"]?.is_edit && (
              <div onClick={() => navigate(`edit/${params.row.id}`)}>
                <Edit />
              </div>
            )}
            {accessData?.["Subscription Plans"]?.is_delete && (
              <div onClick={() => handleDeleteModal(params.row)}>
                <Delete />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // deletion --------------------------------------------
  const deletePlan = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deletePlan("PlanManagement/deletePlan", "Plan", {
        id: modalState.modalId,
      });
      getPlansData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Subscription Plans Added Yet!"
            subtitle="Click on Add New Plan button to add a Subscription Plan"
            tag="Add New Plan"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <PageHeader title="Subscription Plans" />
      <PageCard>
        <div className="mb-2 space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          <div className="d-flex gap-4">
            {accessData?.["Subscription Plans"]?.is_export && (
              <CustomButton
                text="Export"
                handleClick={() => handleDownload()}
                iconRequired
                icon={<Upload />}
              />
            )}
            {accessData?.["Subscription Plans"]?.is_create && (
              <CustomButton
                text="Add New Plan"
                type="btn-primary"
                handleClick={() => navigate("add")}
              />
            )}
          </div>
        </div>
        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />

        {tableData.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Plan"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default Plans;
