import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import BackBtn from "../../../../atoms/BackBtn";
import InputLayout from "../../../../atoms/InputLayout";
import { getData } from "../../../../../services";
import moment from "moment";

const View = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();

  const [planData, setPlanData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "PlanManagement/getPlanDetailsById",
        token: cookies.t,
        params: { id },
      });
      setPlanData(res);
    };

    fetchData(); // Call the async function
  }, []);
  return (
    <>
      <PageHeader title="View Plan">
        <BackBtn />
      </PageHeader>
      <PageCard classes="view-container">
        <div className="row g-4">
          <InputLayout>
            <label htmlFor="name">Plan Name</label>
            <div className="value">{planData?.name}</div>
          </InputLayout>
          <InputLayout>
            <label htmlFor="period_month">Validity in Months</label>
            <div className="value">{planData?.period_month}</div>
          </InputLayout>
          <InputLayout>
            <label htmlFor="period_day">Validity in Days</label>
            <div className="value">{planData?.period_day}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label htmlFor="visitor_allowed">Visitors Count</label>
            <div className="value">{planData?.visitor_allowed}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Base Price</label>
            <div className="value">{planData?.price}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">GST(%)</label>
            <div className="value">{planData?.gst}</div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <InputLayout>
            <label htmlFor="">Total Price incl. GST</label>
            <div className="value">{planData?.total_amount}</div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Created On</label>
            <div className="value">
              {moment.utc(planData?.created_date).format("DD/MM/YYYY")}
            </div>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Status</label>
            <div
              className={`fw-bold ${
                planData?.is_active ? "text-success" : "text-error"
              }`}
            >
              {" "}
              {planData?.is_active ? "Active" : "Inactive"}
            </div>
          </InputLayout>
        </div>

        <div className="row g-4 mt-1">
          <label htmlFor="">Description</label>
          <div class="ql-container ql-snow" style={{border: 0}}>
            <div
              className="ql-editor p-0 mt-1 value"
              dangerouslySetInnerHTML={{ __html: planData?.description }}
            ></div>
          </div>
        </div>

        <div className="row g-4 mt-1">
          <label htmlFor="">Terms And Conditions</label>
          <div class="ql-container ql-snow" style={{border: 0}}>
          <div
            className="ql-editor p-0 mt-1 value"
            dangerouslySetInnerHTML={{ __html: planData?.terms || "--" }}
          ></div>
          </div>
        </div>
      </PageCard>
    </>
  );
};

export default View;
