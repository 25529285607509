import { Field, Form, Formik } from "formik";
import InputLayout from "../../../../../atoms/InputLayout";
import PageCard from "../../../../../atoms/PageCard";
import * as Yup from "yup";
import PageHeader from "../../../../../atoms/PageHeader";
import style from "./styles.module.scss";
import CustomButton from "../../../../../atoms/CustomButton";
import { Navigate } from "react-router-dom";
import { getData, patchData } from "../../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import { useCallback, useEffect, useState } from "react";
import CheckboxWrapper from "../../../../../atoms/CheckBoxWrapper/Checkbox";

const SettingsFormSection = ({
  title,
  prefix,
  data,
  setFieldValue,
  values,
}) => (
  <div className={style[`${prefix.toLowerCase()}_customer_container`]}>
    <PageHeader title={title} type="small" className="mb-0" />
    <Form>
      <div className={`mb-3 ${style.settings_checkbox_container}`}>
        <label htmlFor={`${prefix}_force`}>IS FORCE UPDATE?</label>
        <CheckboxWrapper
          type="checkbox"
          id={`${prefix}_force`}
          name={`${prefix}_force`}
          checked={values[`${prefix}_force`]}
          value={values[`${prefix}_force`]}
          onChange={() => {
            setFieldValue(`${prefix}_force`, !values[`${prefix}_force`]);
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor={`${prefix}_Version`}>LATEST VERSION*</label>
        <Field
          type="text"
          id={`${prefix}_Version`}
          name={`${prefix}_Version`}
          placeholder="Enter LATEST VERSION*"
        />
      </div>
      <div className="form-group mt-3">
        <label htmlFor={`${prefix}_Title`}>TITLE*</label>
        <Field
          type="text"
          id={`${prefix}_Title`}
          name={`${prefix}_Title`}
          placeholder="Enter TITLE*"
        />
      </div>
      <div className="form-group mt-3">
        <label htmlFor={`${prefix}_Message`}>MESSAGE*</label>
        <Field
          type="text"
          id={`${prefix}_Message`}
          name={`${prefix}_Message`}
          placeholder="Enter MESSAGE*"
        />
      </div>
    </Form>
  </div>
);

const Settings = () => {
  const [cookies] = useCookies(["t"]);
  const [tableData, setTableData] = useState([]);

  const dataConfig = [
    { prefix: "customer_Android", index: 2 },
    { prefix: "customer_IOS", index: 3 },
    { prefix: "terminal_Android", index: 0 },
    { prefix: "terminal_IOS", index: 1 },
  ];

  const initialData = dataConfig.reduce((acc, { prefix, index }) => {
    acc[`${prefix}_force`] = tableData[index]?.is_force_update || false;
    acc[`${prefix}_Version`] = tableData[index]?.app_version || "";
    acc[`${prefix}_Title`] = tableData[index]?.title || "";
    acc[`${prefix}_Message`] = tableData[index]?.message || "";
    return acc;
  }, {});

  const validationSchema = Yup.object({
    customer_Android_Version: Yup.string().required(
      "Latest version is required"
    ),
    customer_Android_Title: Yup.string().required("Title is required"),
    customer_Android_Message: Yup.string().required("Message is required"),
    customer_IOS_Version: Yup.string().required("Latest version is required"),
    customer_IOS_Title: Yup.string().required("Title is required"),
    customer_IOS_Message: Yup.string().required("Message is required"),
    terminal_Android_Version: Yup.string().required(
      "Latest version is required"
    ),
    terminal_Android_Title: Yup.string().required("Title is required"),
    terminal_Android_Message: Yup.string().required("Message is required"),
    terminal_IOS_Version: Yup.string().required("Latest version is required"),
    terminal_IOS_Title: Yup.string().required("Title is required"),
    terminal_IOS_Message: Yup.string().required("Message is required"),
  });

  const getUserData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "AdminConfiguration/getAllAppSettings",
        params: {},
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleSubmit = async (values) => {
    try {
      const data = dataConfig.map(({ prefix, index }) => ({
        id: tableData[index]?.id || index + 1,
        type: prefix.split("_")[0],
        is_force_update: values[`${prefix}_force`],
        app_version: values[`${prefix}_Version`],
        title: values[`${prefix}_Title`],
        message: values[`${prefix}_Message`],
      }));
      console.log("dataa :", data);

      const res = await patchData({
        endpoint: "AdminConfiguration/editAppSettings",
        token: cookies.t,
        data,
      });

      if (res.status) {
        toast.success("Settings Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        getUserData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader title="App Settings" type="small" />
      <Formik
        initialValues={initialData}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, isValid, setFieldValue, values }) => (
          <>
            <h4 className="fw-bolder">Terminal App</h4>
            <hr />
            <div className={style.customer_main_container}>
              <div className={style.Android_customer_container}>
                <SettingsFormSection
                  title="Android"
                  prefix="terminal_Android"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div className={style.IOS_customer_container}>
                <SettingsFormSection
                  title="IOS"
                  prefix="terminal_IOS"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
            </div>
            <h4 className="fw-bolder mt-4">Customer App</h4>
            <hr />
            <div className={style.customer_main_container}>
              <div className={style.Android_customer_container}>
                <SettingsFormSection
                  title="Android"
                  prefix="customer_Android"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>

              <div className={style.IOS_customer_container}>
                <SettingsFormSection
                  title="IOS"
                  prefix="customer_IOS"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <CustomButton
                text="Update Settings"
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleSubmit(values)}
                disabled={!isValid || !dirty}
              />
              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  Navigate(-1);
                }}
              />
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default Settings;
