export const calculateAmountWithGST = (originalAmount, gstRate) => {
    // Check if GST rate is provided and greater than 0
    if (gstRate && gstRate > 0) {
        const gstAmount = (originalAmount * gstRate) / 100;
        let totalAmount = originalAmount + gstAmount;
        
        // Round the total amount only when there are more than 2 decimal places
        if (Number.isInteger(totalAmount) === false) {
            totalAmount = totalAmount?.toFixed(2);
        }
        
        return totalAmount;
    } else {
        if (originalAmount && Number.isInteger(originalAmount) === false) {
            return originalAmount?.toFixed(2);
        }
        return originalAmount;
    }
};
