import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import TabStrip from "../../../molecules/tabstrip";
import { Box } from "@mui/material";

import UserManagement from "./tabs/UserManagement";
import Vehicles from "./tabs/Vehicles";
import Settings from "./tabs/Settings";
import PrivacyPolicy from "./tabs/PrivacyPolicy";
import TermsAndConditions from "./tabs/Terms&Conditions";
import SupportRequests from "./tabs/SupportRequest";
import ActivityLogs from "./tabs/ActivityLogs";
import { useSelector } from "react-redux";

const Configurations = () => {
  const navigate = useNavigate();

  const accessData = useSelector((state) =>  state?.accessData?.accessData)

  const [activeTab, setActiveTab] = useState("User Management");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const is_create = accessData?.Configurations?.is_create
  const is_edit = accessData?.Configurations?.is_edit
  const is_delete = accessData?.Configurations?.is_delete

  let data 

  data = useMemo(() => [
    {
      id: 0,
      label: "User Management",
      route: "user-management",
      view: <UserManagement is_create={is_create} is_delete={is_delete} is_edit={is_edit}/>,
    },
    {
      id: 1,
      label: "Vehicles",
      route: "usery",
      view: <Vehicles is_create={is_create} is_delete={is_delete} is_edit={is_edit}/>,
    },

    {
      id: 5,
      label: "Support Requests",
      route: "userys",
      view: <SupportRequests />,
    },
    {
      id: 6,
      label : 'Activity Logs',
      route : 'activity-logs',
      view : <ActivityLogs/>
    }
  ]);

  if(accessData?.Configurations?.is_create) {
    data.splice(2, 0,
      {
        id: 2,
        label: "App Settings",
        route: "userys",
        view: <Settings />,
      },
      {
        id: 3,
        label: "Privacy Policy",
        route: "userys",
        view: <PrivacyPolicy />,
      },
      {
        id: 4,
        label: "Terms And Conditions",
        route: "userys",
        view: <TermsAndConditions />,
      },
    )
  }



  const _getComponet = (tabs) => {
    return data
      .filter((item) => item.label == tabs)
      .map((item) => item.view)[0];
  };

  return (
    <>
      <PageHeader title="Configurations" />
      <PageCard classes="p-3 pt-0">
        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2 }}>{_getComponet(activeTab)}</Box>
        </Box>
      </PageCard>
    </>
  );
};

export default Configurations;
