import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useCookies } from "react-cookie";
import { useParams , useNavigate} from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import PageCard from "../../../../atoms/PageCard";
import InputLayout from "../../../../atoms/InputLayout";
import SelectDropdown from "../../../../molecules/Dropdown";
import { getData, postData } from "../../../../../services";
import Error from "../../../../atoms/Error";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import { DateSelector } from "../../../../organisms/MonthYearSelector";
import CustomButton from "../../../../atoms/CustomButton";
import { confimationStyles , errorStyles} from "../../../../../assets/styles/toast";
import { patchData } from "../../../../../services";
import { getValidityText } from "../../../../../helpers/getValidityText";

const Add = ({ type = "add" }) => {
  const { id } = useParams();
  const navigate = useNavigate()
  const [cookies] = useCookies(["t"]);
  const [plans, setPlans] = useState([]);
  const [business, setBusiness] = useState([]);
  const [paymentDetails, setpaymentDetails] = useState()
  const [details, setDetails] = useState();
  const [isSaving, setIsSaving] = useState(false)

  const getPlans = async () => {
    const res = await getData({
      endpoint: "PlanManagement/getPlanList",
      token: cookies.t,
    });
    setPlans(res);
  };

  const fetchBusinesses = async () => {
    const res = await getData({
      endpoint : 'BusinessManagement/getBusinessList',
      token : cookies.t
    })
    setBusiness(res)
  }

  const fetchData = async (id) => {
    const res = await getData({
      endpoint: "BusinessManagement/getBusinessDetailsById",
      token: cookies.t,
      params: { id },
    });
    setDetails(res);
  };

  const getPaymentDetail = async() => {
    const res = await getData({
      endpoint :  'PaymentManagement/getPaymentDetailsById',
      token : cookies.t,
      params : {id}
    })
    setpaymentDetails(res)
    fetchData(res.business_id)
  }

  useEffect(() => {
    fetchBusinesses()
    getPlans()
    if(type === 'edit'){
      getPaymentDetail()
    }
  },[])

  const initialData = {
    business_id: paymentDetails?.business_id || details?.id ||null,
    country_code  : paymentDetails?.country_code || details?.country_code || '+91',
    contact_number: paymentDetails?.contact_number || details?.phone_number || null,
    email: paymentDetails?.email || details?.email,
    address: paymentDetails?.address || details?.official_address || null,
    payment_mode: paymentDetails?.payment_mode || null,
    amount : paymentDetails?.amount || details?.amount || null,
    plan_id : paymentDetails?.plan_id || details?.plan_id || null,
    subscription_start_date : paymentDetails?.subscription_start_date || details?.start_date || null,
    subscription_end_date : paymentDetails?.subscription_end_date || details?.end_date || null,
    transaction_id : paymentDetails?.transaction_id || null , 
    purchase_date : paymentDetails?.purchase_date || null,
    // plan_validity : `${paymentDetails?.period_month ? `${paymentDetails.period_month} ${paymentDetails.period_month > 1 ? 'Months' : 'Month'}` : ''} ${paymentDetails?.period_day ? `${paymentDetails.period_day} ${paymentDetails.period_day > 1 ? 'Days' : 'Day'}` : ''}`
  };

  const validationSchema = Yup.object().shape({
    business_id: Yup.string().required("Company Name is Required"),
    transaction_id: Yup.string().required("Transaction ID is Required"),
    payment_mode :  Yup.string().required('Payment Mode id Required'),
    purchase_date : Yup.string().required('Purchase Date is required.'),
    amount : Yup.number().required('Amount is required')
  });

  const handleSubmit = async (values) => {
    setIsSaving(true)

    const selectedPlan = plans.find((plan) => plan?.value === values.plan_id);
    const data = { ...values, plan_name: selectedPlan ? selectedPlan.label : '' };

    try{
      const res = await postData({
        endpoint : 'PaymentManagement/addPayment',
        token : cookies.t,
        data :  data
      })

      if(res) {
        toast.success('Payment Added Successfully', {style : confimationStyles ,  duration : 1000})
      }
      navigate(-1)
    } catch(error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }


  }

  const handleEdit = async (values) => {
    const selectedPlan = plans.find((plan) => plan?.value === values.plan_id);
    const data = { ...values, plan_name: selectedPlan ? selectedPlan.label : '' };

    try {
      const res = await patchData({
        endpoint: "PaymentManagement/editPayment",
        token: cookies.t,
        data,
        params: {id: id },
      });
      if (res) {
        toast.success("Payment Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }


  }
  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <PageHeader
            title={
              type === "add" ? "Add Payment Details" : "Edit Payment Details"
            }
          >
            <BackBtn />
          </PageHeader>

          <PageCard>
            <div className="row g-4">
              <InputLayout>
                <label htmlFor="name">Company Name</label>
                <SelectDropdown
                  placeholder="Select Company"
                  data={business}
                  handleBlur={(e) => {
                    handleBlur(e)
                    setFieldTouched("business_id", true);
                  }}
                  onChange={(option) => {
                    fetchData(option);
                    setFieldValue("business_id", option);
                  }}
                  selectedValue={initialData?.business_id}
                  isError={touched.business_id && errors.business_id}
                />

                {touched.business_id && errors.business_id && (
                  <Error error={errors.business_id} />
                )}

                {console.log('valyes', values)}
                {console.log('errors', errors)}
              </InputLayout>

              <InputLayout>
                <label htmlFor="name">Contact Number</label>
                <div className="d-flex gap-3">
                  <div className="col-4">
                    <SelectDropdown
                      data={countryCodeData}
                      placeholder="Code"
                      onChange={(option) => {
                        setFieldValue("country_code", option);
                      }}
                      selectedValue={values?.country_code}
                      isDisabled
                    />
                  </div>
                  <div className="col-8 pe-0" style={{ flex: "auto" }}>
                    <Field
                      type="text"
                      name="contact_number"
                      placeholder="Enter Contact Number"
                      disabled
                      className={`w-100 h-100 ${
                        touched.contact_number && errors.contact_number
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (values.country_code === "+91") {
                          // Enforce a maximum length of 10 digits
                          if (numericInput.length > 10) {
                            numericInput = numericInput.slice(0, 10);
                          }
                          setFieldValue("contact_number", numericInput);
                        } else {
                          setFieldValue("contact_number", numericInput);
                        }
                      }}
                    />
                  </div>
                </div>

                {touched.contact_number && errors.contact_number && (
                  <Error error={errors.contact_number || errors.contact_number} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="email">Email Id</label>
                <Field
                  type="text"
                  disabled
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`${
                    touched.email && errors.email ? "error-input" : ""
                  }`}
                />
                {touched.email && errors.email && (
                  <Error error={errors.email} />
                )}
              </InputLayout>
            </div>

            <div className="row mt-1 g-4">
              <InputLayout>
                <label htmlFor="email">Transaction Id</label>
                <Field
                  type="text"
                  id="transaction_id"
                  name="transaction_id"
                  placeholder="Enter Transaction Id"
                  className={`${
                    touched.transaction_id && errors.transaction_id
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.transaction_id && errors.transaction_id && (
                  <Error error={errors.transaction_id} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="email">Payment Mode</label>
                <Field
                  type="text"
                  id="payment_mode"
                  name="payment_mode"
                  placeholder="Enter Payment Mode"
                  className={`${
                    touched.payment_mode && errors.payment_mode
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.payment_mode && errors.payment_mode && (
                  <Error error={errors.payment_mode} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Plan Name</label>
                <SelectDropdown
                  data={plans}
                  placeholder="Select Plan"
                  onChange={(option) => {
                    setFieldValue("plan_id", option?.value);
                  }}
                  selectedValue={values?.plan_id}
                  format="all"
                  isDisabled
                />
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="email">Plan Validity</label>
                <Field
                  type="text"
                  id="plan_validity"
                  name="plan_validity"
                  placeholder="Enter Plan Validity"
                  disabled
                  value = {getValidityText(details?.period_month,details?.period_day)}
                />
              </InputLayout>

                <InputLayout>
                  <label htmlFor="">Amount</label>
                  <Field
                    type="number"
                    id="amount"
                    name="amount"
                    step={1}
                    placeholder="Enter Amount"
                    className={`${
                      touched.amount && errors.amount ? "error-input" : ""
                    }`}
                  />

                  {touched.amount && errors.amount && (
                    <Error error={errors?.amount || "Amount is Required"} />
                  )}
                </InputLayout>

              <InputLayout>
                <label htmlFor="email">Purchase Date</label>
                <DateSelector
                  placeholder="Select Purchase Date"
                  onChange={(date) => setFieldValue("purchase_date", date)}
                  selectedDate={values?.purchase_date}
                  handleBlur={(e) => {
                    setFieldTouched("purchase_date", true);
                    handleBlur(e);
                  }}
                  minDateData={values.min_date}
                  isError={touched?.purchase_date && errors?.purchase_date}
                />

                {touched.purchase_date && errors.purchase_date && (
                  <Error error={errors.purchase_date} />
                )}
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
            <InputLayout>
                <label htmlFor="">Subscription Start Date</label>
                <DateSelector
                  placeholder="Select Subscription Start Date"
                  onChange={(date) => setFieldValue("subscription_start_date", date)}
                  selectedDate={initialData?.subscription_start_date}
                  handleBlur={(e) => {
                    setFieldTouched("subscription_start_date", true);
                    handleBlur(e);
                  }}
                  maxDateData={values?.subscription_end_date}
                  isError={touched.subscription_start_date && errors.subscription_start_date}
                  disabled
                />
                {touched.subscription_start_date && errors.subscription_start_date && (
                  <Error error={errors.subscription_start_date} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Subscription End Date</label>
                <DateSelector
                  placeholder="Select Subscription End Date"
                  onChange={(date) => setFieldValue("subscription_end_date", date)}
                  selectedDate={values?.subscription_end_date}
                  handleBlur={(e) => {
                    setFieldTouched("subscription_end_date", true);
                    handleBlur(e);
                  }}
                  minDateData={values.subscription_start_date}
                  isError={touched?.subscription_end_date && errors?.subscription_end_date}
                  disabled
                />

                {touched.subscription_end_date && errors.subscription_end_date && (
                  <Error error={errors.subscription_end_date} />
                )}
              </InputLayout>

              <InputLayout>
              <label htmlFor="">Address</label>
              <Field
                  type="text"
                  as="textarea"
                  id="address"
                  name="address"
                  placeholder="Enter  Address"
                  className={`${
                    touched.address && errors.address
                      ? "error-input"
                      : ""
                  }`}
                  disabled
                />
                {touched.address && errors.address && (
                  <Error error={errors.address} />
                )}

              </InputLayout>
            </div>

            {/* {console.log('vals', values)} */}

            <div className="d-flex gap-3 ">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Create Payment
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving}
                >
                  Edit Payment
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Add;
