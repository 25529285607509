import React from 'react'

const PasswordStrengthIndicator = ({ password, isPasswordValid, touched }) => (
    <div
      className={`password_strength ${
        !isPasswordValid && touched ? 'conditions_not_met' : ""
      }`}
    >
      <p className='condition_heading'>Password must:</p>
      <Condition met={password.length >= 8} description="Minimum 8 characters" />
      <Condition met={/[A-Z]/.test(password)} description="At least one uppercase character" />
      <Condition met={/[a-z]/.test(password)} description="At least one lowercase character" />
      <Condition met={/[0-9]/.test(password)} description="At least one numeric character" />
      <Condition met={/[!@#$%^&*]/.test(password)} description="One special character" />
    </div>
  );
  
  const Condition = ({ met, description }) => (
    <div className='condition_wrapper'>
      <div className={`condition ${met ? 'met' : ""}`}></div>
      <span className='condition_description'>{description}</span>
    </div>
  );

export default PasswordStrengthIndicator