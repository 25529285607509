import { Form, Formik, FormikHelpers , Field} from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { postData } from '../../../../services';

import toast from 'react-hot-toast';
import { confimationStyles, errorStyles } from '../../../../assets/styles/toast';
import Error from '../../../atoms/Error';

const ForgotPassForm = () => {
    const [pageError, setPageError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
  
    const emailLink = <a href="mailto:help@xyz.com" style={{ textDecoration: 'underline' }}>help@pxyz.com</a>;
  
    const initialValues = {
      email: '',
    };
  
    const validationSchema = yup.object().shape({
      email: yup.string().email('Invalid email address').required('Email is required'),
    });
  
    const handleSubmit = async (values, { resetForm }) => {
      try {
        const lowercaseEmail = values.email.toLowerCase();
        const updatedValues = { ...values, email: lowercaseEmail };
        const { data: resultData } = await postData({
          endpoint: 'AdminAuth/forgotPassword',
          data: {},
          params: updatedValues,
        });
  
        if(resultData.status){
          toast.success(resultData.message,{duration : 1000 , style : confimationStyles})
          navigate('/auth/login')
        } else {
          toast.error(resultData.message, {duration : 1000 , style : errorStyles})
          setPageError('This email is not registered with us. Please enter your registered email. If your email has changed, please email us.')
        }
      } catch (error) {
        console.log(error);
      }
    };
    

    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isValid, dirty, handleSubmit, isSubmitting, touched, errors, setFieldValue,handleBlur, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="">Email</label>
                <Field
              type="text"
              name="email"
              placeholder="Enter Email Address"
              className={`${
                (touched.email && errors.email) || pageError
                  ? "error-input"
                  : ""
              }`}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
            />
              {touched.email && errors.email && <Error error={errors.email} />}
              {pageError && values.email && !errors.email && <Error error={pageError} />}
            </div>
  
            <button className="btn btn-primary w-100 mt-4" disabled={isSubmitting} type="submit">
              Send Link
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
              )}
            </button>
  
            <button
              className="btn btn-outline-dark w-100 mt-4"
              type="button"
              onClick={() => navigate('/auth/login')}
            >
              Back to login
            </button>
          </Form>
        )}
      </Formik>
    );
  
}

export default ForgotPassForm