import CustomButton from "../../atoms/CustomButton";
import CloseButton from "./CloseButton";
import './styles.scss'

const Modal = ({ width = "400px", onClose, isActive , title,name, onClick,id, type = 'Delete', description}) => {
  const styles = {
    width: width,
  };
  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body py-4">
          <CloseButton onClose={onClose} />
          <p className="modal-heading mb-0 mt-2" >{type} {title}</p>
          {type === 'Delete' ? <p className="modal-description">Are you sure do you want delete <span style={{fontWeight : 800, color :'#545C6E' }}>{name}</span> from the {title} list.</p>
            : <p className="modal-description">{description}</p>
          }

          <div className="d-flex w-100 mt-3">
          <CustomButton text="No" handleClick={onClose} style={{ flex: '1 0 45%' }} iconRequired={false}/>
            {/* Adjust the style for the "Confirm" button */}
            <button className="btn btn-primary mt-0" onClick={onClick} style={{ flex: '1 0 45%', marginLeft: '10px' }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
