import React from 'react'
import {Outlet} from 'react-router-dom'
import Logo from '../../../assets/branding/main-logo.png'
import style from './authlayout.module.scss'


const AuthLayout = ({children}) => {
  return (
    <div className={style.auth_screen}>
    <div className={style.auth_image}>
      {/* <img
        src={Logo}
        className={style.logo_image}
        alt="company-logo"
        loading="eager"
      /> */}
    </div>
    <div className={style.auth_content}>
      <div className={style.auth_child}>
        <Outlet />
      </div>
    </div>
  </div>
  )
}

export default AuthLayout