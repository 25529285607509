import React from "react";
import style from "./styles.module.scss";

const PageHeader = ({ title, children, type = "large", className }) => {
  const headerClass =
    type === "small" ? style.small_page_header : style.page_header;

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <h3 className={`${headerClass} ${className}`}>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
