import React, { useEffect, useState , useCallback} from 'react'
import PageHeader from '../../../../../atoms/PageHeader'
import Search from '../../../../../molecules/search'
import DateRangePicker from '../../../../../organisms/dateRangePicker'
import NoContentIcon from '../../../../../icons/NocontentIcon'
import NoContentCard from '../../../../../atoms/NoContentCard'
import Pagination from '../../../../../molecules/Pagination'
import DetailCard from './DetailCard'
import { useCookies } from 'react-cookie'
import {getData} from '../../../../../../services/index'
import IconCircle from '../../../../../atoms/IconCircle'
import addMonths from "date-fns/addMonths";


const ActivityLogs = () => {
  const [cookies] = useCookies(['bid'])
  const defaultToday = new Date();
  const [tableData, setTableData] = useState([])
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [date, setDate] = useState({start_date : addMonths(defaultToday, -1), end_date :  defaultToday})

    useEffect(() => {
      (async() => {
        const res = await getData({
          endpoint : 'ActivityLogs/getActivityLogs',
          params : {
            type : 'Super Admin',
            search_query : searchedValue,
            page : currentPage,
            page_limit : itemsPerPage,
            start_date : date?.start_date,
            end_date : date?.end_date
          }
        })

        setTableData(res)

      })()
    },[searchedValue, currentPage, itemsPerPage, date])

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
      }, []);
    
      // Handle items per page change
      const handleItemsPerPageChange = useCallback((perPage) => {
        setCurrentPage(1);
        setItemsPerPage(perPage);
      }, []);
  
    return (
      <>
        <div className="d-flex space-between">
          <PageHeader title="Activity Logs" type="small" />
          <div className="d-flex gap-3 align-items-center">
            <Search
              onSearchEmit={(value) => {
                setSearchedValue(value);
                if (value.length > 2) {
                  if (currentPage !== 1) setCurrentPage(1);
                }
              }}
              placeholder='Search By Action'
            />
            <DateRangePicker  setDate={setDate} />
          </div>
  
     
        </div>

        <>
        <div className="activities mb-3">
            {tableData?.data?.length > 0 ? (
              tableData.data.map((item) => <DetailCard key={item.id} data={item} />)
            ) : (
              <NoContentCard
                title="No Log Found"
                type="Company"
                icon={<NoContentIcon />}
                small={true}
              />
            )}
          </div>
  
          {tableData?.total_record > 25 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={tableData?.total_record}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      </>
    );
};

export default ActivityLogs