// import React, { useEffect, useState } from "react";
// import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
// import "./styles.scss";

// const PermissionSelector = ({ label, identifier }) => {
//   const [selectedPermissions, setSelectedPermissions] = useState();
//   const types = ["Is Create?", "Is Edit?", "Is Delete", "Is Export", "Is View"];

//   const handleCheckboxChange = (type) => {
//     const key = `${type.toLowerCase().replace("?", "").replace(" ", "_")}`;
//     setSelectedPermissions((prev) => ({
//       ...prev,
//       [key]: !prev[key],
//     }));
//   };

//   useEffect(() => {
//     selectedPermissions((prev) => ({ ...prev, module: label }));
//   },[label])

//   useEffect(() => {
//     console.log('sel ==> ', selectedPermissions)
//   },[selectedPermissions])

//   return (
//     <div className="permission_selector">
//       <div className="label">{label}</div>
//       <div className="selector">
//         {types?.map((type) => {
//           const key = `${type
//             .toLowerCase()
//             .replace("?", "")
//             .replace(" ", "_")}`;
//           return (
//             <div key={key} className="checkbox_container mb-4">
//               <label>{type}</label>
//               <CheckboxWrapper
//                 checked={!!selectedPermissions[key]}
//                 onChange={() => handleCheckboxChange(type)}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default PermissionSelector;

import React, { useEffect, useState } from "react";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import "./styles.scss";

// Import statements...

const PermissionSelector = ({ label, onChange, selected }) => {
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const types = ["Is Create?", "Is Edit?", "Is Delete", "Is Export", "Is View"];

  useEffect(() => {

    console.log('selected', label, selected )
    
    // Update selected permissions when 'selected' prop changes
    if (selected && typeof selected === 'object') {
      // console.log('sel',selected)
      setSelectedPermissions(selected);
    }
  }, [selected]);

  const handleCheckboxChange = (type) => {
    const key = `${type.toLowerCase().replace("?", "").replace(" ", "_")}`;
    setSelectedPermissions((prev) => ({
      ...prev,
      [key]: !prev?.[key],
    }));
  };

  useEffect(() => {
    // Call 'onChange' callback when 'selectedPermissions' changes

    if (onChange && Object.keys(selectedPermissions).length > 0) {
      onChange({ ...selectedPermissions, module: label });
    }
  }, [selectedPermissions]);

  return (
    <div className="permission_selector">
      <div className="label">{label}</div>
      <div className="selector">
        {types?.map((type) => {
          const key = `${type.toLowerCase().replace("?", "").replace(" ", "_")}`;
          return (
            <div key={key} className="checkbox_container mb-4">
              <label>{type}</label>
              <CheckboxWrapper
                checked={!!selectedPermissions?.[key]} // Check if the permission is selected
                onChange={() => handleCheckboxChange(type)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissionSelector;




