import React from "react";
import PageHeader from "../../../atoms/PageHeader";
import "./styles.scss";

const NoFound = () => {
  return (
    <div className="not-found">
      <div className="header">404</div>

      <div className="subheader">
        Sorry, we were unable to find that page you are looking for.
      </div>

      <div className="link">
        Start from <a href="/">Home Page</a>
      </div>
    </div>
  );
};

export default NoFound;
