import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import Pagination from "../../../molecules/Pagination";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import CustomButton from "../../../atoms/CustomButton";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import View from "../../../icons/View";
import { useDelete } from "../../../../hooks/useDelete";
import toast from "react-hot-toast";
import {
  errorStyles,
  confimationStyles,
} from "../../../../assets/styles/toast";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import moment from "moment";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";

const Business = () => {
  const [cookies] = useCookies(["t"]);

  const accessData = useSelector((state) => state?.accessData?.accessData);

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "SuperAdminReport/getReportBusiness",
      token: cookies.t,
      fileName: "BusinessReport",
    });
    // console.log("RES", res);
    if (res) {
      toast.success("Business Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const getBusinessData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "BusinessManagement/getAllBusiness",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getBusinessData();
  }, [getBusinessData]);

  const columns = [
    {
      field: "name",
      headerName: "Business Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "primary_phone",
      headerName: "Primary Phone No.",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.phone_number && params.row.country_code}{" "}
            {params.row.phone_number}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "contact_name",
      headerName: "Contact Person",
      sortable: true,
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "Subscription Ends At",
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.end_date).format("DD/MM/YYYY hh:mm a")}
          </span>
        );
      },
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.is_active ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => navigate(`view/${params.row.id}`)}>
              <View />
            </div>
            {accessData?.Business?.is_edit && (
              <div onClick={() => navigate(`edit/${params.row.id}`)}>
                <Edit />
              </div>
            )}
            {accessData?.Business?.is_delete && (
              <div onClick={() => handleDeleteModal(params.row)}>
                <Delete />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // deletion --------------------------------------------
  const deletePlan = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deletePlan("BusinessManagement/deleteBusiness", "Business", {
        business_id: modalState.modalId,
      });
      getBusinessData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Business Added Yet!"
            subtitle="Click on Add New Business button to add a business"
            tag="Add New Business"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <PageHeader title="Business" />
      <PageCard>
        <div className="mb-2 space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          <div className="d-flex gap-4">
            {accessData?.Business?.is_export && (
              <CustomButton
                text="Export"
                handleClick={() => handleDownload()}
                iconRequired
                icon={<Upload />}
              />
            )}

            {accessData?.Business?.is_create && (
              <CustomButton
                text="Add New Business"
                type="btn-primary"
                handleClick={() => navigate("add")}
              />
            )}
          </div>
        </div>

        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />

        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Business"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default Business;
