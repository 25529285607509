import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import { useDelete } from "../../../../hooks/useDelete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import CustomButton from "../../../atoms/CustomButton";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Pagination from "../../../molecules/Pagination";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import moment from "moment";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";
import { getValidityText } from "../../../../helpers/getValidityText";

const Payments = () => {
  const [cookies] = useCookies(["t"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction Id",
      sortable: true,
      minWidth: 200,
    },
    {
      field: "business_name",
      headerName: "Company Name",
      sortable: true,
      minWidth: 200,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      sortable: true,
      minWidth: 200,
      renderCell: (params) => {
        return <span>{params.row.country_code || '+91'} {params.row.contact_number}</span>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      minWidth: 200,
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Amount (in Rs.)",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "plan_validity",
      headerName: "Plan Validity",
      sortable: true,
      minWidth: 150,
      renderCell: (params) => {
        const validity = getValidityText(
          params.row.period_month,
          params.row.period_day
        );
        return <span>{validity}</span>;
      },
    },
    {
      field: "purchase_date",
      headerName: "Purchase Date",
      minWidth: 150,

      sortable: true,
      renderCell: (params) => {
        return (
          <span
            title={moment
              .utc(params.row.purchase_date)
              .format("MMM D , YYYY hh:mm a")}
          >
            {moment.utc(params.row.purchase_date).format("MMM D , YYYY")}
          </span>
        );
      },
    },
    {
      field: "subscription_start_date",
      headerName: "Subscription Start Date",
      minWidth: 200,

      sortable: true,
      renderCell: (params) => {
        return (
          <span title={params.row.subscription_start_date}>
            {moment
              .utc(params.row.subscription_start_date)
              .format("MMM D , YYYY hh:mm a")}
          </span>
        );
      },
    },
    {
      field: "subscription_end_date",
      headerName: "Subscription End Date",
      minWidth: 200,

      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {moment
              .utc(params.row.subscription_end_date)
              .format("DD/MM/YYYY hh:mm a")}
          </span>
        );
      },
    },
    {
      headerName: "Actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => navigate(`view/${params.row.id}`)}>
              <View />
            </div>
            {accessData?.Payments?.is_edit && (
              <div onClick={() => navigate(`edit/${params.row.id}`)}>
                <Edit />
              </div>
            )}
            {accessData?.Payments?.is_delete && (
              <div onClick={() => handleDeleteModal(params.row)}>
                <Delete />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const getPaymentsData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "PaymentManagement/getAllPayment",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getPaymentsData();
  }, [getPaymentsData]);

  // pagination

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // deletion --------------------------------------------
  const deletePayment = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.transaction_id,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deletePayment("PaymentManagement/deletePayment", "Payment", {
        id: modalState.modalId,
      });
      getPaymentsData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "SuperAdminReport/getReportPayment",
      token: cookies.t,
      fileName: "Payments Report",
    });

    if (res) {
      toast.success("Payments Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Payments Added Yet!"
            subtitle="Click on Add Payment Details button to add a Payment"
            tag="Add Payment Details"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  return (
    <>
      <PageHeader title="Payments" />
      <PageCard>
        <div className="mb-2 space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          <div className="d-flex gap-4">
            {accessData?.Payments?.is_export && (
              <CustomButton
                text="Export"
                handleClick={() => handleDownload()}
                iconRequired
                icon={<Upload />}
              />
            )}
            {accessData?.Payments?.is_create && (
              <CustomButton
                text="Add Payment Details"
                type="btn-primary"
                handleClick={() => navigate("add")}
              />
            )}
          </div>
        </div>

        <div className="table_with_scroll">
          <CustomDatagrid
            getRowId={(row) => row.id}
            rows={tableData?.data || []}
            columns={columns}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
          />
        </div>

        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Payment"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default Payments;
