import React from "react";
import "./_custom_checkbox.scss";
// a wrapper class for a regular HTML checkbox
function CheckboxWrapper(props) {
  return (
    <label className="check-container" style={{ paddingLeft: "28px" }}>
      <input
        type="checkbox"
        style={{ marginRight: "8px" }} // Adjust styling as needed
        {...props}
        className="checkbox"
      />
      <span className="checkmark"></span>
    </label>
  );
}

export default CheckboxWrapper;
